import styled from 'styled-components';

import back from '../assets/backFull.jpg';
import back2 from '../assets/backMobile.png';

const LandingWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  background-image: url(${back});
  background-color: #fff;
  background-position: center center;
  background-size: 1920px;
  background-repeat: no-repeat;
  /* display: flex; */
  /* justify-content: center; */
  /* background-size: cover; */
  @media only screen and (max-width: 1000px) {
    display: none;
  }
`;

const Success = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #ff0684;
`;

const Modal = styled.div`
  position: fixed;
  left: auto;
  right: auto;
  background: #ffffff;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.25);
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff0684;
`;

const Landing = styled.div`
  padding: 50px;
  max-width: 1440px;
  margin: 0 auto;
  @media (max-width: 600px) {
    padding: 20px;
  }
`;

const Logo = styled.div`
  width: 381px;
  margin-bottom: 50px;
`;

const Title = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  font-size: 120px;
  line-height: 146px;
  text-transform: uppercase;
  color: #000000;
  text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.25);
  margin-bottom: 20px;
`;

const SubTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 28px;
  text-transform: uppercase;
  color: #000000;
`;

const Text = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 14px;
`;

const InputContainer = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 15px;
  background: #fff;
  border-radius: 18px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.25);
  align-items: center;
  max-width: 650px;
  margin-bottom: 14px;
  font-style: normal;
  font-family: Gotham;
  input {
    width: 100%;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #000;
    border: none;
    background: none;
    :focus {
      outline: none;
    }
  }
`;

const Button = styled.button`
  height: 55px;
  background: linear-gradient(312.43deg, #c00063 2.05%, #ec008c 50%);
  border-radius: 12px;
  padding: 0 25px;
  border: none;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  height: 45px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Form = styled.div`
  display: flex;
  align-items: center;
`;

//////////////////////////////////////////////////////////////////

const LandingMobile = styled.div`
  width: 100%;
  display: none;
  background: #fff;
  padding-bottom: 50px;

  @media only screen and (max-width: 1000px) {
    display: block;
  }
`;

const LogoMobile = styled.div`
  margin: 14px 0 0 20px;
  img {
    width: 190px;
  }
  /* z-index: 99; */
`;

const Picture = styled.img`
  width: 100%;
  /* margin-top: -20px; */
`;

const TitleMobile = styled(Title)`
  font-size: 36px;
  line-height: 44px;
  text-align: center;
`;

const SubTitleMobile = styled(SubTitle)`
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 48px;
  text-align: center;
`;

const TextMobile = styled(Text)`
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  margin-bottom: 9px;
`;

const InputContainerMobile = styled(InputContainer)`
  max-width: 100%;
  height: 45px;
  margin: 0 20px 9px 20px;
  padding: 0 8px;

  input {
    font-size: 14px;
    line-height: 17px;
    margin-left: 5px;
  }
`;

const ButtonMobile = styled(Button)`
  height: 33px;
  padding: 0 16px;
`;

const SuccessMobile = styled(Success)`
  text-align: center;
  display: flex;
  justify-content: center;
`;

export {
  LandingWrapper,
  Title,
  SubTitle,
  Logo,
  InputContainer,
  Button,
  Landing,
  Text,
  Form,
  Success,
  Modal,
  LandingMobile,
  Picture,
  TitleMobile,
  SubTitleMobile,
  TextMobile,
  InputContainerMobile,
  ButtonMobile,
  LogoMobile,
  SuccessMobile,
};
