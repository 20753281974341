import './App.css';
import {
  InputContainer,
  LandingWrapper,
  Logo,
  SubTitle,
  Title,
  Button,
  Landing,
  Text,
  Form,
  Success,
  LandingMobile,
  Picture,
  TitleMobile,
  SubTitleMobile,
  TextMobile,
  InputContainerMobile,
  ButtonMobile,
  LogoMobile,
} from './styles/LandingStyled';

import logo from './assets/arNaked.svg';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

import { Link } from 'react-router-dom';

import face from './assets/facebook.svg';
import insta from './assets/instagram.svg';
import linked from './assets/linkedin.svg';

import { useEffect, useState } from 'react';

import back from './assets/backMobile.jpg';

import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import { Router, withRouter } from 'react-router-dom';

// Initialize google analytics page view tracking

const App = () => {
  const history = createBrowserHistory();

  const [email, setEmail] = useState('');
  const [modal, setModal] = useState('');
  const trackingId = 'UA-205845769-1';
  ReactGA.initialize(trackingId, { debug: true });
  // console.log(window.innerWidth);

  useEffect(() => {
    // history.listen((location) => {
    //   ReactGA.set({ page: location.pathname }); // Update the user's current page
    //   ReactGA.pageview(location.pathname); // Record a pageview for the given page
    // });
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  console.log(window.location.pathname + window.location.search);

  return (
    <>
      <Router history={history}>
        <LandingWrapper>
          <Landing>
            <Logo>
              <img src={logo} alt='Logo' />
            </Logo>
            <SubTitle style={{ marginBottom: '20px' }}>
              EXPERIENCE SATISFACTION <br /> LIKE NEVER BEFORE WITH TOP <br />
              AUGMENTED REALITY MODELS
            </SubTitle>
            <Title>
              COMING <br />
              SOON
            </Title>

            <Text>Stay tuned with latest updates and news</Text>

            <MailchimpSubscribe
              url={process.env.REACT_APP_MAILCHIMP_URL}
              render={({ subscribe, status, message }) => (
                <CustomForm
                  status={status}
                  message={message}
                  onValidated={(formData) => subscribe(formData)}
                />
              )}
            />
            <Text>Or follow us on</Text>
            <div style={{ display: 'flex' }}>
              <div className='onHover'>
                <img src={face} alt='facebook' />
              </div>
              <div className='onHover'>
                <img src={insta} alt='instagram' />
              </div>
              <div className='onHover'>
                <img src={linked} alt='linkedIn' />
              </div>
            </div>
          </Landing>
        </LandingWrapper>
        <LandingMobile>
          <LogoMobile>
            <img src={logo} alt='Logo' />
          </LogoMobile>
          <Picture src={back} alt='Logo' />
          <TitleMobile>COMING SOON</TitleMobile>
          <SubTitleMobile>
            Experience satisfaction <br />
            like never before with top <br />
            augmented reality models
          </SubTitleMobile>
          <TextMobile>Stay tuned with latest updates and news</TextMobile>
          <MailchimpSubscribe
            url={process.env.REACT_APP_MAILCHIMP_URL}
            render={({ subscribe, status, message }) => (
              <CustomFormMobile
                status={status}
                message={message}
                onValidated={(formData) => subscribe(formData)}
              />
            )}
          />
          <TextMobile>Or follow us on</TextMobile>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className='onHover'>
              <img src={face} alt='facebook' />
            </div>
            <div className='onHover'>
              <img src={insta} alt='instagram' />
            </div>
            <div className='onHover' style={{ margin: 0 }}>
              <img src={linked} alt='linkedIn' />
            </div>
          </div>
        </LandingMobile>
      </Router>
    </>
  );
};

export default App;

const CustomForm = ({ status, message, onValidated }) => {
  let email;
  const submit = (e) => {
    e.preventDefault();
    email &&
      email.value.indexOf('@') > -1 &&
      onValidated({
        EMAIL: email.value,
      });
  };

  return (
    <form onSubmit={(e) => submit(e)}>
      <Form>
        <InputContainer>
          <input
            type='email'
            ref={(node) => (email = node)}
            placeholder='Enter your email address'
          />

          <Button type='submit'>SUBSCRIBE</Button>
        </InputContainer>
      </Form>

      <div>
        {status === 'sending' && <Success>Sending...</Success>}
        {status === 'error' && (
          <Success dangerouslySetInnerHTML={{ __html: message }} />
        )}
        {status === 'success' && (
          <Success dangerouslySetInnerHTML={{ __html: message }} />
        )}
      </div>
    </form>
  );
};

const CustomFormMobile = ({ status, message, onValidated }) => {
  let email;
  const submit = (e) => {
    e.preventDefault();
    email &&
      email.value.indexOf('@') > -1 &&
      onValidated({
        EMAIL: email.value,
      });
  };

  return (
    <form onSubmit={(e) => submit(e)}>
      <Form>
        <InputContainerMobile>
          <input
            type='email'
            ref={(node) => (email = node)}
            placeholder='Enter your email address'
          />

          <ButtonMobile type='submit'>SUBSCRIBE</ButtonMobile>
        </InputContainerMobile>
      </Form>

      <div style={{ textAlign: 'center', marginBottom: '5px' }}>
        {status === 'sending' && <Success>Sending...</Success>}
        {status === 'error' && (
          <Success dangerouslySetInnerHTML={{ __html: message }} />
        )}
        {status === 'success' && (
          <Success dangerouslySetInnerHTML={{ __html: message }} />
        )}
      </div>
    </form>
  );
};
